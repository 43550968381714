<template>
  <div class="main-panel on-scrollbar">
    <Spinner
      v-show="this.$store.state.spinner_waitData"
      class="style-spinner"
    />
    <BaseScore />
  </div>
</template>

<script>
// import axios from 'axios'
// import config from '../config'
import Spinner from '../views/Spinner.vue'
export default {
  components: {
    Spinner,
  },
  data() {
    return {
      submitsurveyUrl: '/webchat/submitsurvey',
      score: 0,
      star: 0,
      note: '',
      sessionid: '',
      spinner_waitData: false,
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll, true)
    window.parent.postMessage(
      {
        widgetTitle: 'ให้คะแนนความพึงพอใจ',
      },
      '*'
    )
  },
  methods: {
    handleScroll(e) {
      console.log(e)
      let el = e.target
      if (el.classList.contains('on-scrollbar') === false) {
        el.classList.add('on-scrollbar')
      }
      setTimeout(() => {
        el.classList.remove('on-scrollbar')
      }, 300)
    },
    // summitSurvey() {
    //   console.log(555);
    //   this.state_thank = true
    //   this.state_thank = this.$store.state.state_thank
    //   let data = {
    //     osat_score: this.$store.state.survey.score,
    //     nps_score: this.$store.state.survey.star,
    //     verbatim: this.$store.state.survey.note,
    //     session_id: this.$store.state.sessionid,
    //   }
    //   console.log(data)
    //   this.spinner_waitData = true
    //   try {
    //     axios
    //       .post(`${config.baseUrl + config.submitSurveyEndpoint}`, data)
    //       .then((res) => {
    //         onclose.log(data);
    //         // Success 🎉
    //         if (res.status == 200) {
    //           console.log('res')
    //           console.log(res)
    //           this.spinner_waitData = false
    //         }
    //       })
    //       .catch((error) => {
    //         console.log(error)
    //         this.$router.push('error-page')
    //       })
    //   } catch (error) {
    //     console.log(error)
    //     this.$router.push('error-page')
    //   }
    // },
  },
}
</script>
<style scoped>
.button-gogo {
  bottom: 10px;
  position: fixed;
}
.style-spinner {
  position: fixed;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.7);
  max-height: 100%;
  width: 100%;
  margin-top: -1.5rem;
}

@media screen and (max-width: 520px) {
  .style-spinner {
    margin-top: 0;
  }
}
.main-panel {
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 100vh;
  overflow: overlay;
}
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 4px;
  border-radius: 4px;
  background-color: transparent;
}

.on-scrollbar::-webkit-scrollbar-track {
  background-color: #dfe6ec;
}

.on-scrollbar::-webkit-scrollbar-thumb {
  background-color: #0050f0;
}
</style>
